<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import { useForm } from 'vee-validate';
    import { object, string } from 'zod';
    import Accordion from 'primevue/accordion';
    import AccordionPanel from 'primevue/accordionpanel';
    import AccordionHeader from 'primevue/accordionheader';
    import AccordionContent from 'primevue/accordioncontent';
    import RadioButton from 'primevue/radiobutton';
    import FormField from '@/components/form-components/FormField.vue';
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import { toTypedSchema } from '@vee-validate/zod';
    import { hasValidationError } from '@/util/hasValidationError';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { useMarketQuery } from '@/composables/market';
    import { stringValueOfParam } from '@/util/param';
    import { useRoute } from 'vue-router';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { customerApi } from '@containex/portal-backend-api-client';
    import { SendCustomerActivationMailResponseStates } from '@containex/portal-backend-dto';
    import { httpClient } from '@/common/api/http-client';

    const props = defineProps<{
        queryEmail?: string;
        queryCompanyId?: string;
    }>();

    const { t } = useI18n();
    const language = stringValueOfParam(useRoute().params.language);
    const { market } = useMarketQuery();

    const marketLowercase = computed(() => market.value?.code.toLowerCase());
    const selectedOption = ref(0);
    const showError = ref(false);

    const schema = object({
        email: string().email(t('ERROR.INVALID_EMAIL')),
        companyId: string().trim().min(1),
    });

    const { errors, defineField, validate, values } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [companyId] = defineField('companyId');
    const [email] = defineField('email');

    companyId.value = props.queryCompanyId?.trim();
    email.value = props.queryEmail?.trim();

    async function sendCustomerActivationMail(): Promise<void> {
        try {
            const { valid } = await validate();

            if (valid) {
                const { data } = await customerApi.sendCustomerActivationMail(httpClient, {
                    email: values.email ?? '',
                    language: language ?? '',
                    market: marketLowercase.value ?? '',
                    companyExternalId: values.companyId ?? '',
                });

                if (data.state === SendCustomerActivationMailResponseStates.match) {
                    await router.push({ name: Navigation.SignupFastTrackSuccess });
                } else if (data.state === SendCustomerActivationMailResponseStates.companyIdExists) {
                    await router.push({ name: Navigation.SignupFastTrackSuccess, query: { isNew: 'true' } });
                } else {
                    showError.value = true;
                }
            }
        } catch {
            showError.value = true;
        }
    }

    async function onSubmit(): Promise<void> {
        showError.value = false;
        switch (selectedOption.value) {
            case 0: {
                await sendCustomerActivationMail();
                break;
            }
            case 1: {
                await router.push({ name: Navigation.SignupNew });
                break;
            }
        }
    }
</script>

<template>
    <div class="signup-selection-container">
        <h2>{{ t('LOGIN.LOGIN_SIGNUP_SELECTION.TITLE') }}</h2>
        <p>{{ t('LOGIN.LOGIN_SIGNUP_SELECTION.DESCRIPTION') }}</p>

        <Accordion v-model:active-index="selectedOption" :multple="false" :value="0">
            <template #collapseicon></template>
            <template #expandicon></template>
            <AccordionPanel
                :value="0"
                :class="{ 'signup-selection-accordion-panel-error': selectedOption === 0 && showError }"
            >
                <AccordionHeader>
                    <RadioButton id="existingCustomerSelection" v-model="selectedOption" :value="0" />
                    <label for="existingCustomerSelection">
                        {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.SELECTION_EXISTING_CUSTOMER_NUMBER') }}
                    </label>
                </AccordionHeader>
                <AccordionContent>
                    <div class="content-container">
                        <p class="content-container-description">
                            {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.EXISTING_CUSTOMER_NUMBER_DESCRIPTION') }}
                        </p>
                        <form @submit.prevent>
                            <FormField :label="t('FORM.CUSTOMER_NUMBER')" :error-message="errors.companyId">
                                <InputText
                                    v-model="companyId"
                                    :invalid="hasValidationError(errors.companyId) && selectedOption === 0"
                                    class="full-width"
                                    :class="showError ? 'error-border' : ''"
                                />
                            </FormField>
                            <FormField :label="t('FORM.EMAIL')" :error-message="errors.email">
                                <InputText
                                    v-model="email"
                                    class="full-width"
                                    :invalid="hasValidationError(errors.email) && selectedOption === 0"
                                    :class="showError ? 'error-border' : ''"
                                />
                            </FormField>
                        </form>
                        <ErrorTag v-if="showError" :message="t('LOGIN.LOGIN_SIGNUP_SELECTION.CUSTOMER_NOT_FOUND')" />
                    </div>
                </AccordionContent>
            </AccordionPanel>
            <AccordionPanel :value="1">
                <AccordionHeader>
                    <RadioButton id="nonExistingCustomerSelection" v-model="selectedOption" :value="1" />
                    <label for="nonExistingCustomerSelection" data-testid="signup-selection-new-registration">
                        {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.SELECTION_NON_EXISTING_CUSTOMER_NUMBER') }}
                    </label>
                </AccordionHeader>
            </AccordionPanel>
        </Accordion>
        <Button
            class="button"
            :label="t('LOGIN.LOGIN_SIGNUP_SELECTION.CONTINUE')"
            data-testid="signup-selection-continue-button"
            @click="onSubmit()"
        />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    @include main.for-breakpoint-md {
        .signup-selection-container {
            margin: main.$spacing-8 auto 0;

            & button {
                width: unset;
            }
        }

        .content-container {
            & input {
                width: 50%;
            }
        }
    }

    .signup-selection-container {
        background: main.$color-white;
        padding: main.$spacing-6;
        max-width: main.$breakpoint-md;
    }

    .signup-selection-accordion-panel-error {
        border-color: main.$color-error-red;
    }

    .p-accordionheader {
        justify-content: flex-start;

        & label {
            margin-left: main.$spacing-4;
            cursor: pointer;
        }

        & p {
            margin-bottom: main.$spacing-4;
            margin-top: 0;
        }
    }

    .content-container {
        margin-left: main.$spacing-7;
    }

    .content-container-description {
        margin: 0;
    }

    .button {
        margin-top: main.$spacing-5;
        width: 100%;
    }
</style>
